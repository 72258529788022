import React, { useState, useRef, useEffect } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa6";

const CustomSelect = ({ value, onSendData, options, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleOptionSelect = (option) => {
    setIsOpen(false);
    if (onChange) onChange(option); // Notify parent
    if (onSendData) onSendData(option);
  };

  // useEffect(() => {
  //   alert("Bank List: " + JSON.stringify(options));  // This will show the bankList
  // }, [options]);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative">
      <button
        onClick={toggleDropdown}
        className="w-full p-4 border-2 border-gray-200 text-gray-700 rounded-lg text-left flex justify-between items-center"
      >
        {value?.name || placeholder}
        {isOpen ? <FaCaretUp /> : <FaCaretDown />}
      </button>
      {isOpen && (
       <ul
       className="absolute w-full mt-2 border rounded-lg bg-white z-50 max-h-60 overflow-y-auto shadow-lg"
     >
       {options.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
         <li
           key={option.code}
           onClick={() => handleOptionSelect(option)}
           className="p-3 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
         >
           {option.name} {option.image && (<div><img src={option.image} alt={option.name} className="h-10" /></div>)}
         </li>
       ))}
     </ul>
      )}
    </div>
  );
};

export default CustomSelect;
