import { motion, AnimatePresence } from "framer-motion";
import PaymentModal from "../components/Modal";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Input, Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchPaymentDetails } from "../api/paymentApi";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { FaPlugCircleXmark } from "react-icons/fa6";
import { api } from "../config/apiConfig";
import Logo from "../assets/payscribe_blk.png";
import { useCallback } from "react";
import { Helmet } from "react-helmet";
import usePaymentCallback from "../hooks/usePaymentCallback";
import ErrorPage from "../components/pages/ErrorPage";
import PaymentMobileCard from "../components/PaymentMobileCard";
import { ChevronUp } from 'lucide-react';
import SwipeableModal from "../components/SwipeableModal";
/**
 * The main component of the payment link page.
 * This component displays the payment link form if the link is valid and active.
 * If the link is invalid or inactive, it displays an appropriate error message.
 * @return {JSX.Element} The JSX element representing the page content.
 */
const Index = () => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  // const basicInfoValidator = yup.object().shape({
  //   name: yup.string().required(),
  //   email: yup.string().email().required(),
  //   phoneNumber: yup.string().required(),
  // });
  

  const basicInfoValidator = yup.object().shape({
    name: yup.string().required("Please enter your full name"),
    email: yup
      .string()
      .email("Invalid email")
      .required("Please enter your email address"),
    phone: yup.string().required("Please enter your phone number"),
  });

  const [displayDescriptionModal, setDisplayDescriptionModal] = useState();

  const { slug } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [payerDetails, setPayerDetails] = useState(() => {
    const paymentState = localStorage.getItem("paymentState");
    const storedPayerDetails = paymentState ? JSON.parse(paymentState) : null
    return storedPayerDetails?.payerDetails || null;
  });
  // const [bankDetails, setBankDetails] = useState(() => {
  //   const storedData = localStorage.getItem('bankDetails');
  //   const parsedData = storedData ? JSON.parse(storedData) : null;
  //   return parsedData?.bankDetails || null; 
  // });
  // const bankDetailsKey = `bankDetails_${slug}`; 

  // const storedBankDetailsKey = localStorage.getItem(`bankDetails_${slug}`);
  // const storedBankDetails = localStorage.getItem("bankDetails");

// State initialization
// const [bankDetails, setBankDetails] = useState(() => 
//   storedBankDetailsKey ? JSON.parse(storedBankDetailsKey) : null
// );

const [bankDetails, setBankDetails] = useState(() => {
  const bankDetailsKey = localStorage.getItem(`bankDetails_${slug}`);
  return bankDetailsKey ? JSON.parse(bankDetailsKey) : null
});

const [displayPaymentModal, setDisplayPaymentModal] = useState(() => {
  return bankDetails ? true : false
});

// console.log({payerDetails});


// const [paymentState, setPaymentState] = useState(() => {
//   const storedPaymentState = localStorage.getItem("paymentState");
//   return storedPaymentState ? JSON.parse(storedPaymentState) : null;
// });
  
  const [success, setSuccess] = useState(false);
  const [currentCallbackView, setCurrentCallbackView] = useState("pending")
  const [swipeStart, setSwipeStart] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState(0);
  usePaymentCallback(setDisplayPaymentModal, setCurrentCallbackView);

  // if (bankDetails) {
  //   alert("Here is the aza:" + bankDetails.account_number);
  // }
if(bankDetails){
  // console.log("Here is the aza:" + bankDetails.account_number);
}

// if (paymentDetails && bankDetails) {
//   // console.log("Bank Key", bankDetails)
//   setDisplayPaymentModal(true);
// }
// else{
//   // setDisplayPaymentModal(false)
// }
// localStorage.clear();

  const location = useLocation();

  // useEffect(() => {
  //   if (payerDetails) {
  //     localStorage.setItem("paymentState", JSON.stringify(payerDetails));
  //   }
  // }, [payerDetails]);
  
  // useEffect(() => {
  //  
  // }, [bankDetails, slug]);
  


//   useEffect(() => {
//     // Prompt confirmation when reload page is triggered
//     window.onbeforeunload = () => { return "" };
        
//     // Unmount the window.onbeforeunload event
//     return () => { window.onbeforeunload = null };
// }, []);


// useEffect(() => {
//   if (storedBankDetails && slug !== localStorage.getItem("paymentSlug")) {
//     setBankDetails(null);
//   }
// }, [slug, storedBankDetails]);

  useEffect(() => {
    // preventDefault()
    const handleBeforeUnload = () => {
      // preventDefault()
      const state = {
        payerDetails,
        displayPaymentModal,
        currentCallbackView,
        timestamp: Date.now(),
        path: location.pathname
      };
      localStorage.setItem('paymentState', JSON.stringify(state));
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    
    handleBeforeUnload();

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [payerDetails, displayPaymentModal, currentCallbackView, location, slug, bankDetails]);

  // Modify the usePaymentCallback hook usage
  // usePaymentCallback((shouldDisplay, view) => {
  //   setDisplayPaymentModal(shouldDisplay);
  //   setCurrentCallbackView(view);
  //   // Save state immediately when payment callback occurs
  //   const state = {
  //     payerDetails,
  //     displayPaymentModal: shouldDisplay,
  //     currentCallbackView: view,
  //     timestamp: Date.now(),
  //     path: location.pathname
  //   };
  //   localStorage.setItem('paymentState', JSON.stringify(state));
  // });


  const handleTouchStart = (e) => {
    setSwipeStart(e.touches[0].clientY);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const currentY = e.touches[0].clientY;
    const diff = swipeStart - currentY;
    setDragOffset(Math.max(0, Math.min(diff, 50)));
  };

  const handleTouchDetailsEnd = () => {
    if (dragOffset < 5) {
      toggleDescriptionModal();
    }
    setIsDragging(false);
    setDragOffset(0);
  }

  const handleTouchEnd = () => {
    if (dragOffset > 25) {
      toggleDescriptionModal();
    }
    setIsDragging(false);
    setDragOffset(0);
  };
  // const formattedAmount = null;

  // const onSuccess = useCallback(() => {
  //   // // console.log("onSuccess called");
  //   setCurrentView("success");
  //   // // console.log("currentView:", currentView);
  // }, [currentView]);
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(basicInfoValidator),
    defaultValues: payerDetails,
  });

  useEffect(() => {
    if (payerDetails) {
      reset(payerDetails);
    }
  }, [payerDetails, reset]);

  // console.log(control);
  useEffect(() => {
    if (displayDescriptionModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [displayDescriptionModal]);


  const openNotificationWithIcon = (type, message, title) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  document.title =
    "Payment Link | " + (paymentDetails ? paymentDetails.title : "Error");

  useEffect(() => {
    const getPaymentDetails = async () => {
      if (!slug) {
        setLoading(false);
        setError("Invalid URL: Slug is missing.");
        return;
      }
      try {
        const data = await fetchPaymentDetails(slug);
        if (data && data.status === true) {
          setPaymentDetails(data.message.details);
        } else if (data.status_code === 400 || data.status_code === 404) {
          setError("The link is invalid or you cannot access this page.");
        } else {
          setError("This link is inactive or has expired.");
        }
      } catch (err) {
        console.error("Error fetching payment details:", err);
        openNotificationWithIcon(
          "error",
          "An error occurred while fetching payment details."
        );
      } finally {
        setLoading(false);
      }
    };

    getPaymentDetails();
  }, [slug]);

  const API_POST_URL = `https://api.payscribe.ng/api/v1/links/${slug}/payer`;

  /**
   * Submits user information to the server and handles the response.
   * @param {Object} data - The user information to be submitted, containing the user's full name, phone number and email.
   * @return {Promise} A promise that resolves or rejects based on the server response.
   */
  // const submitUserInfo = async (data) => {
  //   try {
  //     setLoadingData(true);

  //     const response = await api.post(API_POST_URL, {
  //       name: data.fullName,
  //       phone: data.phoneNumber,
  //       email: data.email,
  //     });
  //     // // console.log("Here is the payer details ->", payerDetails);

  //     if (response.status === 200 && response.data.status === true) {
  //       // // console.log("Response:", response.data);
  //       const payerDetails = response.data.message.details;
  //       // // console.log("Here is the payer details ->", payerDetails);

  //       setPayerDetails(payerDetails);

  //       toggleModalDisplay();
  //     } else {
  //       throw new Error("Unexpected response status");
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error:",
  //       error.response ? error.response.data : error.message
  //     );

  //     openNotificationWithIcon("error", "Network unstable.");
  //   } finally {
  //     setLoadingData(false);
  //   }
  // };

  const submitUserInfo = async (data) => {
    try {
      setLoadingData(true);

      // console.log(payerDetails);


      const response = await api.post(API_POST_URL, {
        name: payerDetails.name,
        phone: payerDetails.phone,
        email: payerDetails.email,
      });

      if (response.status === 200 && response.data.status === true) {
        const newPayerDetails = response.data.message.details;
        setPayerDetails(newPayerDetails);
        
        const state = {
          payerDetails: newPayerDetails,
          displayPaymentModal: true,
          currentCallbackView,
          timestamp: Date.now(),
          path: location.pathname
        };
        localStorage.setItem('paymentState', JSON.stringify(state));
        
        toggleModalDisplay();
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error.message);
      openNotificationWithIcon("error", "Network unstable.");
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Toggles the display state of the payment modal.
   * If the modal is currently open, this function will close it, and vice-versa.
   */

  const toggleModalDisplay = () => {
    setDisplayPaymentModal(!displayPaymentModal);
  };

  const toggleDescriptionModal = () => setDisplayDescriptionModal(!displayDescriptionModal);
  
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setDisplayDescriptionModal(false);
    }
  };

  const formattedAmount = paymentDetails
    ? new Intl.NumberFormat("en-NG", {
        minimumFractionDigits: 0,
      }).format(paymentDetails.amount)
    : null;

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  if (paymentDetails && paymentDetails.status !== "active") {
    return (
      <div className="text-red-600 text-4xl flex justify-center items-center h-screen p-20">
        <Helmet>
          <meta
            name="title"
            content={"Payscribe Payment Link | " + paymentDetails.title}
          />
          <meta name="description" content={paymentDetails.description} />
          <meta name="author" content="Olamide Ashiru" />
        </Helmet>
        <ErrorPage
        title="Inactive link"
        message="Payment to this link has been restricted"
        description="This link is unavailable, contact your issuer if the issue persists"
         />
        {/* <div className="items-center">
          <div className="h-[10em] flex justify-center">
            <FaPlugCircleXmark className="text-[10em] text-red-200" />
          </div>
          <h2>This link is inactive, contact the issuer</h2>
          <div className="justify-center flex">
            <a
              href="https://www.payscribe.co"
              className="bg-primary text-white p-4 rounded my-10"
            >
              Continue
            </a>
          </div>
        </div> */}
      </div>
    );
  }
  // else if (!paymentDetails) {
  //   return (
  //     <div className="animate-pulse p-6 space-y-6">
  //       <div className="h-14 bg-gray-200 rounded-lg w-1/3" />
  //       <div className="space-y-3">
  //         <div className="h-6 bg-gray-200 rounded w-3/4" />
  //         <div className="h-6 bg-gray-200 rounded w-1/2" />
  //       </div>
  //     </div>
  //   );
  // }
  
  else if (paymentDetails) {
    return (
      <div className="grid md:grid-cols-2 grid-col-1">
        <div className="md:flex hidden items-center justify-center md:min-h-screen min-h-auto md:bg-gradient-to-r md:from-white md:to-white bg-gradient-to-r from-blue-500 to-indigo-600">
          <div className="m-3 bg-white backdrop-blur-lg shadow-2xl p-5 sm:w-[350px] w-full rounded-lg">

    <article className="max-w-2xl mx-auto p-6 space-y-8">
      <header className="flex items-center">
        <img 
          src={Logo} 
          alt={`${paymentDetails.business.name} logo`}
          className="h-14 object-contain"
        />
      </header>
      <div className="space-y-6">
        <div className="space-y-2">
          <p className="text-lg text-gray-600 font-medium">
            Payment Request From
          </p>
          <h1 className="text-xl font-semibold text-gray-900">
            {paymentDetails.business.name}
          </h1>
        </div>

        <div className="space-y-2">
          <h2 className="text-2xl font-semibold text-gray-900">
            {paymentDetails.title}
          </h2>
          <p className="text-gray-600 text-lg leading-relaxed">
            {paymentDetails.description}
          </p>
        </div>
      </div>

      <div className="bg-primary rounded-xl p-6 text-center shadow-lg">
        <div className="space-y-1">
          <p className="text-white/80 text-md font-medium">
            To Pay
          </p>
          <p className="text-white text-3xl font-bold tracking-wide">
            <span className="uppercase">{paymentDetails.currency}</span>
            {' '}
            {formattedAmount}
          </p>
        </div>
      </div>
    </article>
          </div>
        </div>
        <motion.div
          className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-indigo-600 md:p-4 p-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="sm:h-auto h-screen sm:w-auto w-screen">
            <div className="sm:w-md sm:max-w-[40em] max-w-full sm:h-auto h-screen sm:w-auto w-screen  bg-white shadow-lg rounded-lg p-5 md:p-8 md:-my-0 -my-0">
              <div className="md:hidden flex my-12 justify-center">
                {" "}
                <img src={Logo} className="h-16 mx-5 px-2" alt="Logo" />
              </div>

              <div className="md:hidden flex my-12 justify-center">
                <PaymentMobileCard 
                paymentDetails={paymentDetails}
                formattedAmount={formattedAmount}
                />
                {/* <div className="grid grid-cols-2 w-full">
                  <div className="flex justify-start flex-col w-full pr-5 border-r">
                  <h4 className="mx-2 my-2 text-2xl text-left font-bold">
                    {paymentDetails.business.name}
                  </h4>
                  <h4 className="mx-2 my-2 text-2xl text-left font-semibold">
                    {paymentDetails.title}
                  </h4>
                  </div>
                  <div className="flex items-center h-full">
                                    <p className="font-bold text-3xl m-3 pl-3 text-center uppercase">
                    {paymentDetails.currency} {formattedAmount}
                  </p>
                  </div>
                </div>

                <button 
                onClick={toggleDescriptionModal}
                className="fixed bg-black cursor-pointer p-4 bottom-0 my-8 rounded-lg text-white text-xl">Show more</button> */}

                {/* {displayDescriptionModal && ( */}
                  {/* {displayDescriptionModal && (
                    <div 
                      className="fixed inset-0 bg-black bg-opacity-50 flex items-end justify-center"
                      onClick={handleOverlayClick}
                    >
                      <div className="bg-white p-10 text-center w-full h-1/2 z-[999999] text-2xl">
                        <div className="my-10">
                          <h2 className="font-bold text-4xl">Description</h2>
                          <div className="flex justify-center items-start my-10 text-gray-500 h-full">
                            {paymentDetails.description}
                          </div>
                        </div>
                      </div>
                    </div>
                )} */}

<button
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onClick={toggleDescriptionModal}
        className="w-full bg-gray-50 hover:bg-gray-100 absolute bottom-0 transition-colors duration-200 py-4 flex flex-col items-center justify-center  group"
      >
        <ChevronUp 
          className="text-gray-600 group-hover:text-gray-900 transition-transform duration-200"
          style={{
            transform: `translateY(${-dragOffset * 0.5}px) scale(${1 + dragOffset * 0.01})`
          }}
          size={24}
        />
        <span className="text-lg font-medium text-gray-600 group-hover:text-gray-900 mt-1">
          Swipe up or tap for details
        </span>
      </button>
      <SwipeableModal
  isOpen={displayDescriptionModal}
  onClose={toggleDescriptionModal}
  paymentDetails={paymentDetails}
  formattedAmount={formattedAmount}
/>
                      {/* <AnimatePresence>
        {displayDescriptionModal && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-50 flex items-end justify-center w-full z-[9999999999] "
          >
            <motion.div 
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "spring", damping: 25, stiffness: 500 }}
              className="bg-white px-5 py-10 text-center w-full h-2/3 z-[9999999999] text-2xl rounded-3xl"
            >
              <div className="py-10 desc-box overflow-y-scroll ">
                <div className="mt-5 mb-10 ">
                <h3 className="font-bold text-4xl text-left">{paymentDetails.title}</h3>
                </div>
                <h2 className="font-bold text-left text-3xl my-5">Description</h2>
                <div className="flex justify-start text-left relative items-start mb-5 pb-5 text-gray-500 ">
                  {paymentDetails.description}
                  {paymentDetails.description}
                  {paymentDetails.description}
                   </div>

                <div className="space-y-1 rounded-xl p-5 text-center text-black mb-5">
          <p className="text-md font-medium">
            To Pay
          </p>
          <p className="text-4xl font-bold tracking-wide py-5">
            <span className="uppercase">{paymentDetails.currency}</span>
            {' '}
            {formattedAmount}
          </p>
        </div>
                <div className="absolute left-0 bottom-0 pb-5 pt-2 w-full flex justify-center bg-white/20 backdrop-blur-md"><span onClick={toggleDescriptionModal} className=" text-7xl bg-primary text-white rounded-full"><CgChevronDownO /></span></div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence> */}

              </div>
              <h2 className="text-3xl font-semibold text-gray-800 text-center mb-6">
                {/* {payerDetails ? payerDetails.name : "Who is paying?"} */}
                Who is paying?
              </h2>

              {success ? (
                <motion.div
                  className="bg-green-500 text-white text-center p-4 rounded-lg"
                  initial={{ scale: 0.8 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  Your form was submitted successfully!
                </motion.div>
              ) : (
                <form
                  onSubmit={handleSubmit(submitUserInfo)}
                  className="space-y-2 w-full"
                >
                  <br />
                  {/* full Name */}
                  <div className="md:my-0 my-5 md:mb-0 mb-1">
                    <label className="block sm:text-xl text-2xl font-medium text-gray-700">
                      Full Name
                    </label>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter your full name"
                          className="mt-2 py-2"
                          value={payerDetails?.name}
                          onChange={(e) => setPayerDetails({ ...payerDetails, name: e.target.value })}
                        />
                      )}
                    />
                    {errors.name && (
                      <p className="text-red-500 text-lg mt-1">
                        {errors.name.message}
                      </p>
                    )}
                  </div>
                  <br />
                  {/* Phone Number */}
                  <div className="md:my-0 my-5 md:mb-0 mb-1">
                    <label className="block sm:text-xl text-2xl font-medium text-gray-700">
                      Phone Number
                    </label>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="08004567890"
                          className="mt-2"
                          value={payerDetails?.phone}
                          onChange={(e) => setPayerDetails({ ...payerDetails, phone: e.target.value })}
                        />
                      )}
                    />
                    {errors.phone && (
                      <p className="text-red-500 text-lg mt-1">
                        {errors.phone.message}
                      </p>
                    )}
                  </div>
                  <br />
                  {/* Email */}
                  <div className="md:my-0 my-5 md:mb-0 mb-1">
                    <label className="block sm:text-xl text-2xl font-medium text-gray-700">
                      Email
                    </label>
                    <Controller
                      name="email"
                      control={control}
                      size="small"
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter your email"
                          className="mt-2"
                          value={payerDetails?.email}
                          onChange={(e) => setPayerDetails({ ...payerDetails, email: e.target.value })}
                        />
                      )}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-lg mt-1">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                  <br />
                  {/* Submit Button */}
                  <div>
                    <motion.button
                      type="submit"
                      disabled={loadingData}
                      className={`w-full md:py-5 py-7 sm:mt-2 mt-10 text-2xl rounded-md bg-primary text-white font-semibold hover:bg-primary/90 focus:outline-none focus:ring-4 focus:ring-indigo-500 focus:ring-opacity-50 ${
                        loadingData ? "cursor-not-allowed opacity-50" : ""
                      }`}
                      whileHover={{ scale: loadingData ? 1 : 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      transition={{ duration: 0.2 }}
                    >
                      {loadingData ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <span className="text-md">Submit</span>
                      )}
                    </motion.button>
                  </div>
                  <br />
                  <div className="text-center m-10 text-lg">
                    By continuing, you allow payscribe to charge you for this
                    payment
                  </div>
                  <div className="flex justify-between mx-5 md:my-10 my-20 md:text-xl text-2xl">
                    <div className="md:my-2 my-10">
                      <a
                        href="https://www.payscribe.co/privacy-policy"
                        className="text-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </div>
                    <div className="md:my-2 my-10">
                      <a
                        href="https://www.payscribe.co/terms-and-conditions"
                        className="text-center"
                      >
                        Terms and condition
                      </a>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </motion.div>
        <div className="payment-modal-content">
          <PaymentModal
      toggleModalDisplay={toggleModalDisplay}
      displayPaymentModal={displayPaymentModal}
      paymentDetails={paymentDetails}
      payerDetails={payerDetails}
      bankDetails={bankDetails}
      setBankDetails={setBankDetails}
      formattedAmount={formattedAmount}
      channel={"payment_link"}
      slug={slug}
      currentCallbackView={currentCallbackView}
    />
        </div>
      </div> 
    );
  } else if (!paymentDetails) {
    return (
//       <div className="flex items-center justify-center h-screen bg-gray-50">
//   <div className="text-center p-8 bg-white shadow-md rounded-lg">
//     <div className="flex justify-center mb-6">
//       <FaPlugCircleXmark className="text-[10em] text-red-300 animate-pulse" />
//     </div>
//     <h2 className="text-2xl font-semibold text-red-600 mb-4">
//       Connection Issue Detected
//     </h2>
//     <p className="text-gray-700 mb-6">
//       There seems to be an issue connecting to the server. Please check your
//       connection and verify the link you are trying to access.
//     </p>
//     <a
//       href="https://payscribe.co/contact"
//       className="inline-block bg-primary text-white px-6 py-3 rounded-md text-lg font-medium shadow hover:bg-primary-dark transition duration-300"
//     >
//       Contact Us
//     </a>
//   </div>
// </div>
<ErrorPage 
/>
 
    );
  } else {
    return (
      <ErrorPage />
      // <div className="text-red-600 text-4xl flex justify-center items-center h-screen">
      //   <div>
      //     <div className="h-[10em] flex justify-center">
      //       <FaPlugCircleXmark className="text-[10em] text-red-200" />
      //     </div>
      //     <h2 className="text-center">
      //       There is an issue connecting to the server
      //     </h2>
      //     <h2 className="text-center">
      //       Check your connection and ensure the Link you are trying to access
      //       is correct.
      //     </h2>
      //     <div className="justify-center flex">
      //       <a
      //         href="https://payscribe.co/contact"
      //         className="bg-primary text-white p-4 rounded my-10"
      //       >
      //         Contact us
      //       </a>
      //     </div>
      //   </div>
      // </div>
    );
  }
};

export default Index;
