import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';


const SwipeableModal = ({ 
  isOpen, 
  onClose, 
  paymentDetails, 
  formattedAmount 
}) => {
  const [dragY, setDragY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const modalRef = useRef(null);
  const dragStartY = useRef(0);
  const dragStartScrollTop = useRef(0);

  const handleTouchStart = (e) => {
    const modalElement = modalRef.current;
    if (!modalElement) return;
    
    dragStartY.current = e.touches[0].clientY;
    dragStartScrollTop.current = modalElement.scrollTop;
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    
    const modalElement = modalRef.current;
    if (!modalElement) return;
    
    const currentY = e.touches[0].clientY;
    const diff = currentY - dragStartY.current;
    
    if (modalElement.scrollTop <= 0) {
      if (diff > 0) {
        setDragY(diff);
        e.preventDefault();
      } else {
        setDragY(0);
      }
    }
  };

  const handleTouchEnd = () => {
    if (dragY > 150) {
      onClose();
    }
    setDragY(0);
    setIsDragging(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-end justify-center w-full z-50"
          onClick={handleOverlayClick}
        >
          <motion.div
            ref={modalRef}
            initial={{ y: "100%" }}
            animate={{ y: dragY }}
            exit={{ y: "100%" }}
            transition={{ 
              type: "spring", 
              damping: 25, 
              stiffness: 500,
              y: { duration: isDragging ? 0 : 0.2 }
            }}
            className="bg-white w-full h-2/3 rounded-t-3xl text-2xl overflow-hidden"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            drag="y"
            dragConstraints={{ top: 0, bottom: 0 }}
            dragElastic={0.2}
          >
            <div className="h-2 w-24 bg-gray-300 rounded-full mx-auto mt-8 mb-3" />
            
            <div className="px-5 py-6 h-full overflow-y-auto">
              <div className="mb-8">
                <h3 className="font-bold text-4xl text-left">{paymentDetails.title}</h3>
              </div>
              
              <h2 className="font-bold text-left text-3xl mb-4">Description</h2>
              <div className="text-left text-gray-500 mb-8">
                {paymentDetails.description}
              </div>

              <div className="mb-10 rounded-xl p-5 text-center text-black">
          <p className="text-md font-medium">
            To Pay
          </p>
          <p className="text-4xl font-bold tracking-wide py-5">
            <span className="uppercase">{paymentDetails.currency}</span>
            {' '}
            {formattedAmount}
          </p>
        </div>
              </div>
                {/* <div className="absolute left-0 bottom-0 pb-5 pt-2 w-full flex justify-center bg-white/20 backdrop-blur-md"><span onClick={onClose} className=" text-7xl bg-primary text-white rounded-full"><CgChevronDownO /></span></div> */}

           
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SwipeableModal;