import React, { useState } from "react";
// import { Controller, useForm } from "react-hook-form";
import { Modal as AntdModal } from "antd";
import Loader from "./Loader/Loader";
import BankDetails from "./pages/bank";
import CardPayment from "./pages/card";
import USSDPayment from "./pages/ussd";
import PayscribeCard from "./pages/payscribe";
import { api } from "../config/apiConfig";
import { MdOutlineCancel } from "react-icons/md";
import { fetchBankDetails, fetchBanks } from "../api/paymentApi";
import Logo from "../assets/payscribe_blk.png";
import BankImg from "../assets/images/bank.svg";
import BankWhiteImg from "../assets/images/bank-white.svg";
import CardWhiteImg from "../assets/images/card-2-svgrepo-com(1).svg";
import CardImg from "../assets/images/card-2-svgrepo-com.svg";
import HashImg from "../assets/images/hash-svgrepo-com.svg";
import HashWhiteImg from "../assets/images/hash-white.svg";
import PayscribeImg from "../assets/images/payscribe.svg";
import PayscribeWhiteImg from "../assets/images/payscribe-white.svg"
import WalletImg from "../assets/images/wallet-svgrepo-com.svg";
import WalletWhiteImg from "../assets/images/wallet-svgrepo-com-white.svg";
import Dev from "../assets/images/logo512.png";
import { FaCaretLeft } from "react-icons/fa";
import { motion } from "framer-motion";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Pending from "./Pending";
import AnimatedSequence from "./animatedloadersequence";

// TODO: Merge all sub modal components into one or Destructure them into more components
// TODO: Clean all duplicates and redundant code
// type Props = {
//     toggleModalDisplay: () => void;
//     displayPaymentModal: boolean;
//     slug: any;
//     paymentDetails: any;
//     payerDetails: any;
//     bankDetails: any;
//     setBankDetails: (bankDetails: any) => void;
//     formattedAmount: number;
// }

/**
 * A modal component that displays payment options and handles payment
 * @param {Object} props Component props
 * @param {boolean} props.displayPaymentModal Whether to display the modal
 * @param {Object} props.paymentDetails Payment details
 * @param {number} props.balance Balance of the payer
 * @param {Object} props.payerDetails Payer details
 * @param {Array} props.bankDetails Bank details
 * @param {Function} props.setBankDetails Set bank details
 * @param {string} props.slug Link slug
 * @param {string} props.channel Payment channel
 * @returns {ReactElement} The modal component
 */
const PaymentModal = ({
  toggleModalDisplay,
  displayPaymentModal,
  paymentDetails,
  balance,
  payerDetails,
  bankDetails,
  setBankDetails,
  slug,
  channel,
  currentCallbackView,
}) => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [palmpayAccount, setPalmpayAccount] = useState("");
  const [walletAccount, setWalletAccount] = useState("");
  const [error, setError] = useState(null);
  // const [currentDisplay, setCurrentDisplay] = useState("pending");
  const [currentDisplay, setCurrentDisplay] = useState(
    currentCallbackView || (bankDetails ? "bank_transfer" : "wallet")
  );
  const [currentMobileOption, setCurrentMobileOption] = useState(bankDetails ? 4 : 1);
  const [currentMobileDisplay, setCurrentMobileDisplay] = useState(bankDetails ? "payment" : "default");
  const [bankList, setBankList] = useState([]);
  const [currentView, setCurrentView] = useState(bankDetails ? "bank_transfer" : "default");
  const [walletError, setWalletError] = useState(null);

  /**
   * Returns a display name for a given payment method.
   *
   * @param {string} method - The payment method.
   * @returns {string} The display name of the payment method.
   */

  const WALLET_VERIFY_URL = `https://api.payscribe.ng/api/v1/links/${slug}/create-order`;

  const getPaymentDisplayName = (method) => {
    switch (method) {
      case "bank_transfer":
        return "Bank";
      case "ussd":
        return "USSD";
      case "cards":
        return "Card";
      case "wallet":
        return "Wallet";
      default:
        return method.charAt(0).toUpperCase() + method.slice(1);
    }
  };

  // console.log(currentDisplay);
  // console.log(currentView);
  // // console.log(bankDetails.account_number);

  const handleResponseData = (data, setDataFunction) => {
    // console.log("HandleResponseData was triggered");
    if (data && data.status === true) {
      setDataFunction(data.message.details);
      // console.log(data.message.details);
    } else if (data.status_code === 400 || data.status_code === 404) {
      setError("The link is invalid or you cannot access this page.");
    } else {
      setError("This link is inactive or has expired.");
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  const PalmpayCheckout = async () => {
    // const palmpayAccountRegex = /^(789|8[01])\d{8}$/;
    // alert("Type shii" + walletError);
    const palmpayAccountRegex = /^[7-9][01]\d{8}$/;
    if (palmpayAccountRegex.test(palmpayAccount)) {
      try {
        setIsLoading(true);
        // console.log("Here is the acct", palmpayAccount);
        const response = await api.post(WALLET_VERIFY_URL, {
          account: palmpayAccount,
          channel: channel,
          provider: "palmpay",
          customer_id: payerDetails.id,
        });
        setIsLoading(false);

        // http://localhost:5001/pay/RHGxpz?callback=true&reference=I3groWkQgKG92cQO0V6NJJESR9LQqaNj

        if (response.status === 200 && response.data.status === true) {
          const walletAccount = response.data.message.details;
          window.location.href = walletAccount.checkout_url;
          setCurrentView("pending");
        } else if (
          response.status_code === 400 ||
          response.status_code === 404
        ) {
          setWalletError("We could not find your account");
        }
      } catch (error) {
        setIsLoading(false);
        setWalletError("Ensure you entered a valid palmpay account");
      }
    } else {
      setWalletError("Ensure you entered a valid palmpay account");
    }
  };

  const PalmpayI = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAWlBMVEVHcEz////////////////////////////////////////////////////v2v/ZrP/05f/Plv+pRP+WGv/hvP+PAf+LAP+0XP+5aP+WDP/IiP+hLv/68v/Zqf/LwsYTAAAADnRSTlMAFEyUyu3/k2tCsUBd2Mw3YV8AAAENSURBVHgBfZMHlgMhCECnspNmLBFr7n/NRTQ9ken8R2eGm4zTvKwA6/I3jcOrjNsCd1m2F7zbw4vsDw92PMGbnI4fTJzP4o0eGpNKG6OVbJQ9jy3eRVsksfrS4o4Et+rREbLel7urvjcyXNgsoMUQASK/sPEyDhPbJVKZmKXM0dBr4sDTMAPJNTAT3ousDKLPRTsP7FUVn8RILYVuDJZhZYiWfHrUGp2zTgK7XQeoMEJhUiZEYl6xukEP0iFBkcgxRKywupXErHPklliOtsK1JsQ+Bd2Q0i2lMlxqKZnUSXEpXGi4Fu1fbYLkJqhHEwQ3odu+buP7I3sMO38Ou78m/QXrr2Z/qV9+h3m6o39o+R/IfBreCgAAAABJRU5ErkJggg==";
  const Palmpay =
    "https://www.palmpay.com/_next/static/media/ToPalmPay.e5cb0343.png?w=384&q=100";

  // // console.log(palmpayAccount);
  // const onSubmit = (data) => {
  //   setIsLoading(true)
  //   // console.log("Here is the acct", data);
  //   // PalmpayCheckout(data);
  // }
  /**
   * Returns an icon for a given payment method based on the method and if it is active or not.
   *
   * @param {string} method - The payment method.
   * @param {boolean} isActive - If the payment method is the currently active method.
   * @returns {string} The icon of the payment method.
   */
  const getIcon = (method, isActive) => {
    switch (method) {
      case "bank_transfer":
        return isActive ? BankWhiteImg : BankImg;
      case "ussd":
        return isActive ? HashWhiteImg : HashImg;
      case "wallet":
        return isActive ? WalletWhiteImg : WalletImg;
      case "payscribe":
        return isActive ? PayscribeWhiteImg : PayscribeImg;
      case "palmpay":
        return Palmpay;
      case "cards":
        return isActive ? CardWhiteImg : CardImg;
      default:
        return undefined;
    }
  };

  // if (currentCallbackView === "failed") {
  //   window.location
  // }

  const formattedAmount = paymentDetails
    ? new Intl.NumberFormat("en-NG", {
        minimumFractionDigits: 0,
      }).format(channel === "invoice" ? balance : paymentDetails.amount)
    : null;

  return (
    <AntdModal
      footer={null}
      // onCancel={currentCallbackView ? null : toggleModalDisplay}
      onCancel={null}
      open={displayPaymentModal}
      className="sm:top-20 top-0 font-sans"
    >
      <div className="payment-modal-showcase">
        <div className="payment-modal-disp ">
          <div className="payment-options-list">
            <div className="payment-option-list-header">
              <h5>Payment Options</h5>
            </div>
            <div>
              <ul className="mb-24">
                {[
                  ...Object.values(paymentDetails.payments),
                  paymentDetails.payments.wallets.length > 0 ? "wallet" : null,
                ]
                  .filter(Boolean)
                  .sort((a, b) => String(b).localeCompare(String(a)))
                  .map((paymentMethod) => {
                    if (!paymentMethod || typeof paymentMethod !== "string")
                      return null;

                    let paymentDisplayName;
                    switch (paymentMethod) {
                      case "bank_transfer":
                        paymentDisplayName = "Bank";
                        break;
                      case "ussd":
                        paymentDisplayName = "USSD";
                        break;
                      case "cards":
                        paymentDisplayName = "Card";
                        break;
                      case "wallet":
                        paymentDisplayName = currentDisplay === "palmpay" || currentDisplay === "payscribe" ? currentDisplay.charAt(0).toUpperCase() + currentDisplay.slice(1) : "Wallet";
                        break;
                      default:
                        paymentDisplayName = paymentMethod;
                    }

                    const getIcon = (method, isActive) => {
                      switch (method) {
                        case "bank_transfer":
                          return isActive ? BankWhiteImg : BankImg;
                        case "ussd":
                          return isActive ? HashWhiteImg : HashImg;
                        case "wallet":
                          return isActive ? currentDisplay === "palmpay" ? PalmpayI : currentDisplay === "payscribe" ? PayscribeWhiteImg :  WalletWhiteImg : WalletImg;
                        case "cards":
                          return isActive ? CardWhiteImg : CardImg;
                        default:
                          return undefined;
                      }
                    };

                    const handleClick = async () => {
                      try {
                        setError(null);
                        setCurrentDisplay(paymentMethod);

                        if (paymentMethod === "wallet") {
                          setCurrentDisplay("wallet");
                          // console.log(currentDisplay);
                          return;
                        }

                        if (!slug || !paymentMethod || !payerDetails?.id) {
                          throw new Error("Missing required information");
                        }

                        // const logUrl = `links/${slug}/tab?type=${paymentMethod}&id=${payerDetails?.id}&channel=${channel}`;
                        // const logResponse = await api.get(logUrl);

                        // if (logResponse.status !== 200) {
                        //   throw new Error("Failed to log payment method");
                        // }

                        if (paymentMethod === "bank_transfer") {
                          const data = await fetchBankDetails(
                            slug,
                            paymentMethod,
                            payerDetails?.id,
                            channel
                          );
                          handleResponseData(data, setBankDetails);
                          if (bankDetails) {
                            const fullDet = {
                              bankDetails: bankDetails,
                              slug: slug
                            }
                            // localStorage.setItem('bankDetails', JSON.stringify(fullDet));
                          }
                        }

                        if (paymentMethod === "ussd") {
                          const data = await fetchBanks(
                            slug,
                            paymentMethod,
                            payerDetails?.id,
                            channel
                          );
                          handleResponseData(data, setBankList);
                        }
                      } catch (error) {
                        setError(error.message);
                      } finally {
                        setLoading(false);
                      }
                    };

                    return (
                      // <li
                      //   key={paymentMethod}
                      //   onClick={handleClick}
                      //   className={`single-payment-link w-full ${
                      //     currentDisplay === paymentDisplayName.toLowerCase() || currentDisplay === paymentMethod ? "active" : ""
                      //   }`}
                      // >
                      //   <div className="flex-div">
                      //     <img
                      //       src={getIcon(
                      //         paymentMethod,
                      //         currentDisplay === paymentDisplayName.toLowerCase() || currentDisplay === paymentMethod
                      //       )}
                      //       alt={paymentMethod ?? ""}
                      //       className="big w-[20px] h-[24px]"
                      //     />
                      //     {paymentDisplayName}
                      //   </div>
                      // </li>
                      <motion.li
      layout
      key={paymentMethod}
      onClick={handleClick}
      className={`relative overflow-hidden cursor-pointer p-5 ${
        currentDisplay === paymentDisplayName.toLowerCase() || 
                      currentDisplay === paymentMethod ? 'bg-primary' : 'bg-white'
      }`}
      whileHover={{ scale: 1.00 }}
      whileTap={{ scale: 0.98 }}
    >
      <motion.div 
        className="flex items-center gap-3 p-3"
        initial={false}
        animate={{
          color: currentDisplay === paymentDisplayName.toLowerCase() || 
          currentDisplay === paymentMethod ? '#ffffff' : '#374151',
        }}
      >
        <motion.div
          className="relative w-8 h-8"
          initial={false}
          animate={{
            scale: currentDisplay === paymentDisplayName.toLowerCase() || 
            currentDisplay === paymentMethod ? 1.1 : 1,
          }}
          transition={{ type: "spring", stiffness: 300, damping: 20 }}
        >
          <motion.img
            src={getIcon(paymentMethod, currentDisplay === paymentDisplayName.toLowerCase() || 
              currentDisplay === paymentMethod
)}
            alt={paymentMethod ?? ""}
            className="w-full h-full object-contain"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          />
        </motion.div>
        
        <motion.span
          layout
          className="text-xl font-medium"
          initial={false}
          animate={{
            fontWeight: currentDisplay === paymentDisplayName.toLowerCase() || 
            currentDisplay === paymentMethod ? 600 : 500,
          }}
        >
          {paymentDisplayName}
        </motion.span>
      </motion.div>

      {(currentDisplay === paymentDisplayName.toLowerCase() || 
                      currentDisplay === paymentMethod) && (
        <motion.div
          layoutId="active-indicator"
          className="absolute inset-0 border-2 border-white"
          initial={false}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 30
          }}
        />
      )}
    </motion.li>
                    );
                  })}

                {/* {showWalletOptions && ( */}
                {/* <div className="wallet-options">
                      {paymentDetails.payments.wallets.map((wallet) => (
                        <li
                          key={wallet}
                          onClick={() => {
                            setCurrentDisplay(wallet);
                            // setShowWalletOptions(false);
                          }}
                          className="single-payment-link w-full"
                        >
                          <div className="flex-div">
                            <img
                              src={getIcon(wallet, currentDisplay === wallet)}
                              alt={wallet}
                              className="big w-[20px] h-[24px]"
                            />
                            {wallet}
                          </div>
                        </li>
                      ))}
                    </div> */}
                {/* )} */}
              </ul>
            </div>
          </div>

          {/* Display selected payment method details */}
          <div className="sm:rounded-xl rounded-none">
            <div className="top-modal">
              <div className="grid-flex">
                <div>
                  <div className="top-company-modal-logo p-1">
                    <button
                      href="https://wa.me/+2349024328949"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open("https://wa.me/+2349024328949", "_blank");
                      }}
                      className="z-[999] absolute top-[1.3em] mx-auto md:opacity-[40%] opacity-[30%] md:w-12 md:h-12 h-[0.3em] cursor-none w-[0.3em]"
                      rel="noreferrer"
                    >
                      <img src={Dev} className="" alt="balabluu" />
                    </button>
                    <img
                      src={Logo}
                      className="h-[5.5em] mx-1 px-2"
                      alt="Logo"
                    />
                  </div>
                </div>
                {payerDetails ? (
                  <div className="p-5">
                    <p>{payerDetails.email}</p>
                    <p>
                      Pay{" "}
                      <b>
                        <span className="currency uppercase">
                          {paymentDetails.currency}{" "}
                        </span>
                        {formattedAmount}
                      </b>
                    </p>
                  </div>
                ) : (
                  <div>
                    {currentCallbackView && (
                      <div className={`absolute top-0 left-0 w-full h-full flex justify-center items-center bg-white z-[999999999999] ${bankDetails ? "" : ""}`}>
                        {currentCallbackView === "success" ? (
                          <div>
                            <div className="flex justify-center flex-col items-center transform md:-translate-y-3 translate-y-[-2em]  font-sans md:h-auto h-screen">
                              <AnimatedSequence className="md:scale-[50%] scale-[60%]" />
                              <motion.span
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 8, duration: 1 }}
                                className="text-2xl text-center font-semibold flex justify-center"
                              >
                                Payment Successful
                              </motion.span>

                              <motion.span
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 10, duration: 1 }}
                                onAnimationComplete={() => {
                                  setTimeout(() => {
                                    window.location.href =
                                      "https://www.payscribe.co/";
                                  }, 5000);
                                }}
                                className="text-xl text-center font-semibold flex justify-center my-5"
                              >
                                You will be redirected away in a few seconds...
                              </motion.span>
                            </div>
                          </div>
                        ) : (
                          <div className="transform md:scale-100 scale-150">
                            <Pending />{" "}
                            <p className="">Awaiting your payment...</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="desktop h-full w-full relative">
              {loading ? (
                <div className="loading-message">
                  <Loader />
                </div>
              ) : error ? (
                <div className="error-message flex items-center flex-col">
                  <p>{error}</p>
                  <button
                    onClick={() => setError(null)}
                    className="flex justify-center items-center text-white bg-primary px-5 py-2 my-10"
                  >
                    Retry
                  </button>
                </div>
              ) : (
                <>
                  {currentDisplay === "payscribe" ? (
                    <div className="">
                      <div>
                        {paymentDetails && payerDetails ? (
                          <div>
                            <div
                        className="flex items-center cursor-pointer"
                        onClick={() => setCurrentDisplay("wallet")}
                      >
                        <FaCaretLeft className="text-4xl" />{" "}
                        <span className="font-semibold m-3">Wallets</span>
                      </div>
                          <PayscribeCard
                            amount={formattedAmount}
                            slug={slug}
                            payerId={payerDetails?.id}
                            channel={channel}
                          />
                          </div>
                        ) : (
                          <div className="card-form-div animate-pulse flex flex-col items-center">
                            <div className="mb-4 h-10 w-[100%] bg-gray-300 rounded"></div>
                            <div className="my-5 h-[16em] w-[80%] bg-gray-300 rounded"></div>
                            <div className="mt-2 h-[2em] w-[100%] bg-gray-300 rounded"></div>
                            <div className="mt-2 h-6 w-40 bg-gray-300 rounded"></div>
                          </div>
                        )}
                      </div>{" "}
                    </div>
                  ) : currentDisplay === "ussd" ? (
                    <div className="">
                      <USSDPayment
                        bankList={bankList}
                        amount={formattedAmount}
                        slug={slug}
                        payerDetails={payerDetails}
                        channel={channel}
                        paymentDetails={paymentDetails}
                        amount_paid={
                          channel === "invoice"
                            ? paymentDetails.amount_paid
                            : null
                        }
                      />{" "}
                    </div>
                  ) : currentDisplay === "wallet" ? (
                    <div className="flex justify-center items-center relative">
                      {paymentDetails.payments.wallets && (
                        <ul className="m-10 w-full">
                          <h2 className="text-center font-semibold mb-8">
                            Choose a wallet to pay with
                          </h2>
                          {paymentDetails.payments.wallets.map((wallet) => (
                            <div className="w-full flex justify-center">
                            <li
                              key={wallet}
                              onClick={() => {
                                setCurrentDisplay(wallet);
                              }}
                              className="cursor-pointer px-10 py-5 my-2 flex justify-between font-semibold text-xl bg-gray-100/80 border border-grey-200 rounded-xl md:w-[90%] w-full items-center "
                            >
                              <span>
                                <img
                                  src={
                                    wallet === "palmpay"
                                      ? Palmpay
                                      : PayscribeImg
                                  }
                                  alt={wallet}
                                  className="w-[24px] h-[24px]"
                                />{" "}
                              </span>
                              <div className="text-left"> {wallet.charAt(0).toUpperCase() + wallet.slice(1)}</div>
                             
                            </li>
                            </div>
                          ))}
                        </ul>
                      )}
                    </div>
                  ) : currentDisplay === "palmpay" ? (
                    <div>
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={() => setCurrentDisplay("wallet")}
                      >
                        <FaCaretLeft className="text-4xl" />{" "}
                        <span className="font-semibold m-3">Wallets</span>
                      </div>
                      <div className="flex justify-center items-center">
                        <form
                          onSubmit={(e) => e.preventDefault(PalmpayCheckout())}
                          className="m-10 w-full"
                        >
                          {/* <form onSubmit={handleSubmit(PalmpayCheckout())} className="m-10 w-full"> */}
                          <div className="md:my-2 my-10">
                            <input
                              type="text"
                              placeholder="Enter your Palmpay Account Number"
                              value={palmpayAccount}
                              maxLength={10}
                              pattern="\d{10}"
                              onChange={(e) => {
                                setPalmpayAccount(e.target.value);
                                setWalletError("");
                              }}
                              className={`p-2 px-5 w-full border ring-none ${
                                walletError
                                  ? `border-red-500`
                                  : `border-blue-800`
                              }  focus:ring-none my-1`}
                            />
                            {walletError && (
                              <span className="text-red-500">
                                {walletError}
                              </span>
                            )}
                          </div>

                          <motion.button
                            type="submit"
                            disabled={isLoading}
                            className={`w-full py-4 my-5 text-2xl rounded-md bg-primary text-white font-semibold hover:bg-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-500 focus:ring-opacity-50 ${
                              isLoading ? "cursor-not-allowed opacity-50" : ""
                            }`}
                            whileHover={{ scale: isLoading ? 1 : 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ duration: 0.2 }}
                          >
                            {isLoading ? (
                              <Spin indicator={antIcon} />
                            ) : (
                              <span className="text-md">Continue</span>
                            )}
                          </motion.button>
                        </form>
                      </div>
                    </div>
                  ) : currentDisplay === "cards" ? (
                    <div className="">
                      <CardPayment
                        payer={payerDetails}
                        amount={formattedAmount}
                        payment={paymentDetails}
                        slug={slug}
                      />{" "}
                    </div>
                  ) : (
                    <div className="">
                      {bankDetails ? (
                        <BankDetails
                        handleResponseData={handleResponseData}
                          bankDetails={bankDetails}
                          setBankDetails={setBankDetails}
                          amount={paymentDetails.amount}
                          expiryAt={bankDetails.expiry_at}
                          payerId={payerDetails?.id}
                          slug={slug}
                          onSuccess={() => setCurrentView("success")} // Pass the setCurrentDisplay function as a prop
                          channel={channel}
                          balance={balance}
                          amount_paid={
                            channel === "invoice"
                              ? paymentDetails.amount_paid
                              : null
                          }
                        />
                      ) : (
                        <div className="card-form-div animate-pulse flex flex-col items-center">
                          <div className="mb-4 h-10 w-[100%] bg-gray-300 rounded"></div>
                          <div className="my-5 h-[16em] w-[80%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-[2em] w-[100%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-6 w-40 bg-gray-300 rounded"></div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            {/* Mobile Modal View */}
            <div className="block w-full sm:hidden mobile-view fixed overflow-hidden sm:h-auto h-screen p-5 ">
              {currentMobileDisplay === "default" ? (
                <div className="card-form-div">
                  {/* <div
                    onClick={toggleModalDisplay}
                    className="absolute top-5 right-10 text-black modal-cancel"
                  >
                    <MdOutlineCancel className="text-5xl" />
                  </div> */}
                  <div className="center">
                    <h3>Please select your preferred mode of payment</h3>
                  </div>
                  <div className="payment-method w-full">
                    {/* {[...Object.values(paymentDetails.payments), ...paymentDetails.payments.wallets].map((method, index) => {
    if (!method || typeof method !== 'string') return null; */}

                    {[
                      ...Object.values(paymentDetails.payments),
                      paymentDetails.payments.wallets.length > 0
                        ? "wallet"
                        : null,
                    ].sort((a, b) => String(b).localeCompare(String(a)))
                      .filter(Boolean)
                      .map((method, index) => {
                        if (!method || typeof method !== "string") return null;

                        return (
                          <div
                            key={index}
                            onClick={async () => {
                              try {
                                setError(null);
                                setCurrentMobileOption(index + 1);
                                setCurrentDisplay(method);

                                if (!slug || !method || !payerDetails?.id) {
                                  throw new Error(
                                    "Missing required information to log payment method"
                                  );
                                }

                                const logUrl = `links/${slug}/tab?type=${method}&id=${payerDetails?.id}&channel=${channel}`;
                                const logResponse = await api.get(logUrl);

                                if (logResponse.status !== 200) {
                                  throw new Error(
                                    "Failed to log payment method"
                                  );
                                }

                                if (method === "bank_transfer") {
                                  const data = await fetchBankDetails(
                                    slug,
                                    method,
                                    payerDetails?.id,
                                    channel
                                  );
                                  handleResponseData(data, setBankDetails);
                                }

                                if (method === "ussd") {
                                  const data = await fetchBanks(
                                    slug,
                                    method,
                                    payerDetails?.id,
                                    channel
                                  );
                                  handleResponseData(data, setBankList);
                                }
                              } catch (error) {
                                setError(`Error: ${error.message}`);
                              } finally {
                                setLoading(false);
                              }
                            }}
                            className={`single-payment-method my-10 ${
                              currentMobileOption === index + 1
                                ? "active bg-primary text-white"
                                : ""
                            }`}
                          >
                            <div className="grid-flex w-full">
                              <div>
                                <img
                                  src={
                                    getIcon(
                                      method,
                                      currentMobileOption === index + 1
                                    ) ?? ""
                                  }
                                  alt={method}
                                  className="w-10 h-10"
                                />
                              </div>
                              <h5 className="font-medium text-3xl">
                                {getPaymentDisplayName(method)}
                              </h5>
                            </div>
                          </div>
                        );
                      })}
                    <br />
                    <button
                      className="w-full p-8 rounded-xl text-2xl bg-primary text-white font-semibold my-10"
                      onClick={() => setCurrentMobileDisplay("payment")}
                    >
                      Continue
                    </button>
                  </div>{" "}
                </div>
              ) : (
                <div className="external">
                  <div
                    className="mt_10 flex my-10 items-center"
                    onClick={() => setCurrentMobileDisplay("default")}
                  >
                    <FaCaretLeft className="text-4xl" />{" "}
                    <span className="font-semibold m-1">
                      Want to use another payment method?
                    </span>
                  </div>
                  {currentDisplay === "payscribe" ? (
                    <div>
                      {/* <div
                        className="flex items-center cursor-pointer"
                        onClick={() => setCurrentDisplay("wallet")}
                      >
                        <FaCaretLeft className="text-4xl" />{" "}
                        <span className="font-semibold m-1">Wallets</span>
                      </div> */}
                      {paymentDetails ? (
                        <div>
                          <div
                        className="flex items-center cursor-pointer"
                        onClick={() => setCurrentDisplay("wallet")}
                      >
                        <FaCaretLeft className="text-4xl" />{" "}
                        <span className="font-semibold m-3">Wallets</span>
                      </div>
                        <PayscribeCard
                          amount={formattedAmount}
                          payerId={payerDetails.id}
                          slug={slug}
                          channel={channel}
                          className="mt-10"
                        />
                        </div>
                      ) : (
                        <div className="card-form-div animate-pulse flex flex-col items-center">
                          <div className="mb-4 h-10 w-[100%] bg-gray-300 rounded"></div>
                          <div className="my-5 h-[16em] w-[80%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-[2em] w-[100%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-6 w-40 bg-gray-300 rounded"></div>
                        </div>
                      )}
                    </div>
                  ) : currentDisplay === "bank_transfer" ? (
                    <div>
                      {bankDetails && payerDetails ? (
                        <BankDetails
                          bankDetails={bankDetails}
                          setBankDetails={setBankDetails}
                          handleResponseData={handleResponseData}
                          amount={paymentDetails.amount}
                          expiryAt={bankDetails.expiry_at}
                          payerId={payerDetails.id}
                          slug={slug}
                          onSuccess={() => setCurrentView("success")} // Pass the setCurrentDisplay function as a prop
                          channel={channel}
                          balance={balance}
                          amount_paid={
                            channel === "invoice"
                              ? paymentDetails.amount_paid
                              : null
                          }
                        />
                      ) : (
                        <div className="card-form-div animate-pulse flex flex-col items-center">
                          <div className="mb-4 h-10 w-[100%] bg-gray-300 rounded"></div>
                          <div className="my-5 h-[16em] w-[80%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-[2em] w-[100%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-6 w-40 bg-gray-300 rounded"></div>
                        </div>
                      )}
                    </div>
                  ) : currentDisplay === "card" ? (
                    <CardPayment
                      payer={payerDetails}
                      payment={paymentDetails}
                      amount={formattedAmount}
                      slug={slug}
                    />
                  ) : currentDisplay === "wallet" ? (
                    <div className="flex justify-center items-center relative">
                      {paymentDetails.payments.wallets && (
                        <ul className="md:m-10 m-5 w-full">
                          <h2 className="text-center font-semibold mb-8">
                            Choose from our selected wallets
                          </h2>
                          {paymentDetails.payments.wallets.map((wallet) => (
                            <li
                              key={wallet}
                              onClick={() => {
                                setCurrentDisplay(wallet);
                              }}
                              className="cursor-pointer px-10 py-8 border border-gray-200 md:my-3 my-5 flex justify-between font-bold bg-gray-200/80 rounded-xl md:w-[80%] w-full items-center "
                            >
                              <span className="">
                                <img
                                  src={
                                    wallet === "palmpay"
                                      ? Palmpay
                                      : PayscribeImg
                                  }
                                  alt={wallet}
                                  className="w-[24px] h-[24px]"
                                />{" "}
                              </span>
                              <span className="">
                              {wallet.charAt(0).toUpperCase() + wallet.slice(1)}
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ) : currentDisplay === "pending" ? (
                    <div>Pending</div>
                  ) : currentDisplay === "palmpay" ? (
                    <div>
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={() => setCurrentDisplay("wallet")}
                      >
                        <FaCaretLeft className="text-4xl" />{" "}
                        <span className="font-semibold m-3">Wallets</span>
                      </div>
                      <div className="flex justify-center items-center">
                        {/* <form className="m-10 w-full" onSubmit={(e) => e.preventDefault(onSubmit())}> */}
                        <form
                          onSubmit={(e) => {
                            e.preventDefault(PalmpayCheckout());
                          }}
                          className="m-10 w-full"
                        >
                          <input
                            type="text"
                            placeholder="Enter your Palmpay Account Number"
                            value={palmpayAccount}
                            maxLength={10}
                            pattern="\d{10}"
                            onChange={(e) => {
                              setPalmpayAccount(e.target.value);
                              setWalletError("");
                            }}
                            className={`p-2 px-5 w-full border-b border-gray-600 ${
                              walletError ? `border-red-500` : `border-blue-800`
                            }  focus:ring-none md:my-2 my-10`}
                          />
                          {walletError && (
                            <span className="text-red-500">{walletError}</span>
                          )}
                          {/* <button type="submit" className="mt-10 bg-primary text-white p-4 w-full rounded-xl">Continue</button> */}
                          <motion.button
                            type="submit"
                            disabled={isLoading}
                            className={`w-full py-5 my-5 text-2xl rounded-md bg-primary text-white font-semibold hover:bg-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-500 focus:ring-opacity-50 ${
                              isLoading ? "cursor-not-allowed opacity-50" : ""
                            }`}
                            whileHover={{ scale: isLoading ? 1 : 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ duration: 0.2 }}
                          >
                            {isLoading ? (
                              <Spin indicator={antIcon} />
                            ) : (
                              <span className="text-md">Continue</span>
                            )}
                          </motion.button>{" "}
                        </form>
                      </div>
                    </div>
                  ) : (
                    <BankDetails
                    bankDetails={bankDetails}
                    setBankDetails={setBankDetails}
                    handleResponseData={handleResponseData}
                    amount={paymentDetails.amount}
                    expiryAt={bankDetails?.expiry_at}
                    payerId={payerDetails.id}
                    slug={slug}
                    onSuccess={() => setCurrentView("success")} // Pass the setCurrentDisplay function as a prop
                    channel={channel}
                    balance={balance}
                    amount_paid={
                      channel === "invoice"
                        ? paymentDetails.amount_paid
                        : null
                    }
                  />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div
        onClick={toggleModalDisplay}
        className={`modal-cancel sm:${currentCallbackView ? "hidden" : "block"} hidden`}
      >
        <img src={CancelIcon} alt="cancel" />
      </div> */}
    </AntdModal>
  );
};

export default PaymentModal;
