import React, { useState } from "react";
import { motion } from "framer-motion";
import { Input, Spin } from "antd";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { api } from "../../config/apiConfig";
import AnimatedSequence from "../animatedloadersequence";
import ArrowBack from "../../assets/images/arrow-back.svg";
import CheckmarkCircle from "../../assets/images/checkmark-circle.svg";
import EmailVerification from "./email";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
const PayscribeCard = ({ amount, slug, payerId, channel, className }) => {



  const [currentView, setCurrentView] = useState("default");
  const [isLoading, setIsLoading] = useState(false); // Initially set to true
  const [payscribeDetails, setPayscribeDetails] = useState(null);

  const PAYSCRIBE_URL = `https://api.payscribe.ng/api/v1/links/${slug}/validate-payscribe`;
  const toggleModalDisplay = () => {
    setCurrentView("verification");
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Email or Payscribe Tag is required')
      .test(
        'valid-username',
        'Enter a valid email or Payscribe Tag',
        (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || true
      ),
    otp: Yup.string()
      .matches(/^[0-9]+$/, "OTP must contain only numbers")
      .length(4, 'OTP must be exactly 4 digits')
      .required('OTP is required'),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });


  const payscribeLogIn = async (credentials) => {
    try {
      setIsLoading(true);
      // Pass in the channel, username and pin to the url
      const response = await api.post(PAYSCRIBE_URL, {
        username: credentials.username,
        pin: credentials.otp,
        channel : channel
      });

      if (response.status === 200) {
        const payscribeDetails = response.data.message.details;
        setPayscribeDetails(payscribeDetails);
        toggleModalDisplay("success");
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
          const errorMessage =
          error.response && error.response.data.description
            ? error.response.data.description === "The user can not be found"
              ? "Email or Payscribe Tag is invalid"
              : error.response.data.description
            : error.message;
        // // console.log(error.response.data.description);
          
      setError("username", { message: errorMessage });
      setError("otp", { message: errorMessage });
      
    } finally {
      setIsLoading(false);
    }
  };

  const reRoutePage = () => {
    setCurrentView("success");
  };

  return (
    <div className={`${className} card-form-div`}>
      
      {/* Default page for the pay with payscribe  */}
      {currentView === "default" ? (
        <>
          <div className={`center font-semibold`}>
            <h3 className="font-semibold">Pay with Payscribe</h3>
            <p className="font-semibold">
              Login to your payscribe account to make payment via your account
            </p>
          </div>
          <div className="">
          <form onSubmit={handleSubmit(payscribeLogIn)}>
  <div className="form-group">
    <label style={{ display: "block" }}>Email / Payscribe Tag</label>
    <div className="w-[100%] md:h-5">
    <Controller
      control={control}
      name="username"
      render={({ field }) => (
        <Input  {...field} 
      className=""
      />
       
      )}
    /> </div>
    {errors.username && <p className=" text-red-500">{errors.username.message}</p>}
  </div>

  <div className="form-group my-20">
    <label style={{ display: "block" }}>Payscribe OTP</label>
    <div className="otp-box">
      <Controller
        control={control}
        name="otp"
        render={({ field }) => (
          <OtpInput
            value={field.value}
            onChange={field.onChange}
            numInputs={4}
            isInputNum
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} type="password" className="text-black h-5 md:h-8"/>}
          />
        )}
      />
      {errors.otp && <p className=" text-red-500">{errors.otp.message}</p>}
    </div>
  </div>

  <motion.button
    type="submit"
    disabled={isLoading}
    className={`w-full py-4 my-5 text-2xl rounded-md bg-primary text-white font-semibold hover:bg-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-500 focus:ring-opacity-50 ${
      isLoading ? "cursor-not-allowed opacity-50" : ""
    }`}
    whileHover={{ scale: isLoading ? 1 : 1.05 }}
    whileTap={{ scale: 0.95 }}
    transition={{ duration: 0.2 }}
  >
    {isLoading ? (
      <Spin indicator={antIcon} />
    ) : (
      <span className="text-md">Submit</span>
    )}
  </motion.button>
</form>

          </div>
        </>
      ) : currentView === "verification" ? (
        <>
          <EmailVerification
            email={`${payscribeDetails.user.username}`}
            onComplete={reRoutePage}
            slug={slug}
            channel={channel}
          />
        </>
      ) : currentView === "success" ? (
        <>
        <div className="flex justify-center flex-col items-center transform md:-translate-y-3 -translate-y-[14em] font-sans md:h-auto h-screen">
          <AnimatedSequence className="scale-[40%]" />
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 8, duration: 1 }}
            className="text-3xl text-center font-semibold flex justify-center"
          >
            Payment Successful
          </motion.span>

          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 10, duration: 1 }}
            onAnimationComplete={() => {
              setTimeout(() => {
                window.location.href = "https://www.payscribe.co/";
              }, 10000);
            }}
            className="text-2xl text-center font-semibold flex justify-center my-5"
          >
            You will be redirected away in a few seconds...
          </motion.span>
        </div>
        </>
      ) : (
        <React.Fragment>
          {/* <div className="center">
                                    <h3>Payment Summary</h3>
                                </div> */}
          <div className="payment-div-body">
            <div className="payment-sect-success">
              <img
                src={CheckmarkCircle}
                alt="checkmark-circle"
                className="circle-checkmark"
              />
              <div className="mt_5">
                <h4>Transaction Successful</h4>
                <div className="align-center">
                  <p className="body-text">
                    Thank you for your purchase. we have sent the reciept to
                    your email.
                  </p>
                </div>
              </div>
            </div>
            <div className="button-cover">
              <button
                id="myBtn"
                onClick={toggleModalDisplay}
                className="text-white bg-primary p-4 rounded-xl w-full my-4 "
              >
                Share Receipt
              </button>
            </div>
            <div className="button-cove">
              <div className="flex-div">
                <Link to="/" className="btn btn-blank flex-div">
                  <img src={ArrowBack} alt="times icon" />
                  Go Back
                </Link>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
export default PayscribeCard;
