import { useEffect } from 'react';
import { redirect, useSearchParams } from 'react-router-dom';
import { api } from '../config/apiConfig';

const usePaymentCallback = (setDisplayPaymentModal, setCurrentView, setBankDetails) => {
  const [searchParams] = useSearchParams();
  // const bankDetails = JSON.parse(localStorage.getItem("bankDetails"));

  const slug = window.location.pathname.split('/').pop(); // Get the current slug

  
  // const bankDetailsKey = `${bankDetails?.account_number}_${slug}` || null;
  const bankDetailsKey = localStorage.getItem(`bankDetails_${slug}`);
  // const bankDetails = localStorage.getItem("bankDetails");

  const paymentState = sessionStorage.getItem("paymentState");

  // // console.log({paymentState});
  useEffect(() => {
    const callback = searchParams.get('callback');
    const reference = searchParams.get('reference');

    if (callback === 'true' && reference) {
      setDisplayPaymentModal(true);
      setCurrentView('pending');

      const verifyPayment = async () => {
        try {
          const response = await api.get(`/links/${reference}/verify-payment`);
          
          if (response.data.status === true) {
            if (response.data.status_code === 201) {
              // Payment is still pending
            // window.open("/")

              setCurrentView('pending');
            } else {
              // Payment is successful
              setCurrentView('success');
            }
          } else {
            // window.location.href = "/pay/null";
            window.location.href = "/pay/null";
          }
        } catch (error) {
          console.error('Payment verification failed:', error);
          window.location.href = "/pay/null";
        }
      };

      verifyPayment();

      const pollInterval = setInterval(async () => {
        try {
          const pollResponse = await api.get(`/links/${reference}/verify-payment`);
          
          if (pollResponse.data.status === true && pollResponse.data.status_code !== 201) {
            setCurrentView('success');
            clearInterval(pollInterval);
          } else if (pollResponse.data.status === false) {
            // window.location.href = "/pay/null";
            clearInterval(pollInterval);
          }
        } catch (error) {
          console.error('Polling failed:', error);
          // window.location.href = "/pay/null";
          clearInterval(pollInterval);
        }
      }, 3000); 

      return () => {
        clearInterval(pollInterval);
      };
    }
    else{
      // window.location.href = "/pay/null";
      // if (paymentState && bankDetailsKey) {
      //   // console.log("Bank Key", bankDetailsKey)
      //   setDisplayPaymentModal(true);
      // setCurrentView("default");
      // setBankDetails(JSON.parse(bankDetailsKey));
      // }else{
        setCurrentView(null);
      // setBankDetails(null);


      // }
      // setDisplayPaymentModal(false);
    }
  }, [searchParams, setDisplayPaymentModal,setBankDetails, setCurrentView, paymentState, bankDetailsKey]);
};

export default usePaymentCallback;