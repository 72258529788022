import "./assets/css/index.css";
import "./Styles/main.scss";
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Pay from "./pages/index";
import InvoicePage from "./pages/Invoice.tsx";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Redirect root and general paths */}
                <Route path="/" element={<Navigate to="/pay/null" />} />
                <Route path="/pay" element={<Navigate to="/pay/null" />} />
                <Route path="/inv" element={<Navigate to="/pay/null" />} />

                {/* Specific Routes */}
                <Route path="/pay/:slug" element={<Pay />} />
                <Route path="/inv/:id" element={<InvoicePage />} />

                {/* Catch-all route for invalid paths */}
                <Route path="*" element={<Navigate to="/pay/null" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
