import React from 'react';

const PaymentMobileCard = ({ paymentDetails, formattedAmount }) => {
  return (
    <div 
      className=" w-full bg-white rounded-lg overflow-hidden"
    >
      <div className="grid grid-cols-2 w-full p-6">
        <div className="flex justify-start flex-col w-full pr-5 border-r border-gray-200">
          <h4 className="text-2xl font-bold text-gray-900 mb-2">
            {paymentDetails.business.name}
          </h4>
          <h4 className="text-xl font-semibold text-gray-700">
            {paymentDetails.title}
          </h4>
        </div>
        <div className="flex items-center justify-center h-full">
          <p className="font-bold text-3xl text-center uppercase text-gray-900">
            {paymentDetails.currency} {formattedAmount}
          </p>
        </div>
      </div>
<br/>
      
    </div>
  );
};

export default PaymentMobileCard;