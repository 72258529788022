import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.payscribe.ng/api/v1';


// const TEST_TOKEN = process.env.REACT_APP_TEST_TOKEN || 'pk_live_key_ungkvvMePYxWoVqxGsqGTaSzvIvHL10';
// const API_URL = 'http://localhost:8080/https://api.payscribe.ng/api/v1';

// // // console.log('API_URL:', API_URL);
// // // console.log('TEST_TOKEN:', TEST_TOKEN);

const api = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer NO_AUTHENTICATION_TOKEN`,
  },
  withCredentials: false,
});


// const api = axios.create({
//   baseURL: API_URL,
//   timeout: 10000,
//   headers: {
//     "Content-Type": "application/json",
//     "Authorization": `Bearer ${TEST_TOKEN}`,
//   },
//   withCredentials: false,
// });


api.interceptors.request.use(
  (request) => {
    // // // console.log('Starting Request', request);
    return request;
  },
  (error) => {
    // console.error('Request Error', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // // // console.log('Response:', response);
    return response;
  },
  (error) => {
    // console.error('Response Error:', error);
    return Promise.reject(error);
  }
);

// // // console.log('API_URL:', API_URL);
// // // console.log('TEST_TOKEN:', TEST_TOKEN);

export { api };


