import React, { useRef, useState, useEffect } from 'react';
import Loader from '../components/Loader/Loader';
import { useParams } from "react-router-dom";
import html2pdf from 'html2pdf.js';
import { FaDownload } from "react-icons/fa6";
import html2canvas from 'html2canvas';
import { ImCross } from "react-icons/im";
import Logo from '../assets/payscribe_blk.png';
import PaymentModal from '../components/Modal';
import { fetchInvoiceDetails, fetchBankDetails } from '../api/paymentApi';
import MaskedEmail from '../components/MaskedEmail';
import MaskedPhone from '../components/MaskedPhone';
import { AnyObjectSchema } from 'yup';
import { IoWarningOutline } from "react-icons/io5";
import { FaPlugCircleXmark } from "react-icons/fa6";
import {Helmet} from 'react-helmet';
import usePaymentCallback from "../hooks/usePaymentCallback";
import ErrorPage from "../components/pages/ErrorPage";


/**
 * InvoicePage component is responsible for rendering the invoice details
 * and providing functionalities such as downloading the invoice as PDF,
 * previewing the invoice, and handling payment actions.
 * 
 * It fetches invoice details using the provided invoice ID from the URL
 * parameters and displays the information including invoice items, total
 * amounts, discounts, and customer details. The component offers payment
 * options if the invoice has not been paid and shows payment status.
 * 
 * State variables:
 * - invoiceDetails: Holds the details of the invoice fetched from the API.
 * - loading: Indicates whether the invoice details are being loaded.
 * - error: Holds any error messages encountered during fetching.
 * - showModal: Controls the display of the invoice preview modal.
 * - previewImage: Stores the data URL of the invoice preview image.
 * - displayPaymentModal: Controls the visibility of the payment modal.
 * - paymentDetails, payerDetails, bankDetails: Additional details for the payment process.
 * 
 * Functions:
 * - getInvoiceDetails: Fetches the invoice details using the invoice ID.
 * - toggleModalDisplay: Toggles the visibility of the payment modal.
 * - payNow: Initiates the payment process.
 * - downloadInvoiceAsPDF: Downloads the invoice as a PDF.
 * - handlePreview: Generates and shows the preview of the invoice.
 * - handleClose: Closes the preview modal.
 * - getCurrencySymbol: Returns the symbol of the given currency.
 * - calculateTotal: Calculates the total amount of the invoice including discounts and taxes.
 * - formattedAmount: Formats a given amount to the currency format.
 * - formatDate: Formats a date string into a readable format.
 */
const InvoicePage = () => {

  interface InvoiceDetails {
    invoice_no: ReactNode;
    title: string;
    description: string;
    has_paid: boolean;
    invoice_date: string;
    amount_paid: string;
    due_date: string;
    id: number;
    currency: string;
    amount: number;
    note: string;
    discount: {
      map: any;
    };
    items: {
      map: any;
      description: string;
      qty: number;
      rate: number;
      amount: number;
      unit: number;
      type: string;
    };
    business: {
      name: string;
      address: string;
    };
    created_at: string;
    customer: {
      name: string;
      email: string;
      phone: string;
    };
  }

  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { id } = useParams();
  const invoiceRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [displayPaymentModal, setDisplayPaymentModal] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);
    const [currentCallbackView, setCurrentCallbackView] = useState("pending")
  
  usePaymentCallback(setDisplayPaymentModal, setCurrentCallbackView);



  useEffect(() => {
  /**
   * Fetches the invoice details using the invoice ID provided in the URL
   * parameters. If the invoice ID is invalid, it sets an error message
   * and disables loading. If the request fails, it logs the error in the
   * updates the invoiceDetails state with the fetched data.
   * 
   * @function getInvoiceDetails
   * @returns {void}
   */
    const getInvoiceDetails = async () => {
      if (!id) {
        setLoading(false);
        setError("Invalid URL: Invoice is missing.");
        return;
      }
      try {
        const data = await fetchInvoiceDetails(id);
        if (data && data.status === true) {
          setInvoiceDetails(data.message.details);
        } else if (data.status_code === 400 || data.status_code === 404) {
          setError("The link is invalid or you cannot access this page.");
        } else {
          setError("This link is inactive or has expired.");
        }
      } catch (err) {
        console.error("Error fetching payment details:", err);
      } finally {
        setLoading(false);

      }
    };

    getInvoiceDetails();
  }, [id]);
  const toggleModalDisplay = () => {
    setDisplayPaymentModal(!displayPaymentModal);
  };

  document.title = "Invoice | " + (invoiceDetails ? invoiceDetails.title : "Error");
  // const description = document.querySelector('meta[name="description"]').content;

  // description = invoiceDetails?.description;


  const payNow = () => {
    toggleModalDisplay();
  }
/**
 * Downloads the current invoice as a PDF file.
 * Utilizes the html2pdf library to convert the invoice DOM element
 * referenced by `invoiceRef` into a PDF.
 * The file is saved with the naming convention 'Payscribe <business-name>/<invoice-id>.pdf'.
 * Ensures that the `invoiceRef` is not null before attempting the conversion.
 */
  const downloadInvoiceAsPDF = () => {

    if (invoiceRef.current) {

  /**
   * Opens a modal displaying a preview of the invoice as a PNG image.
   * The image is generated using the html2canvas library.
   * The `invoiceRef` is used to reference the DOM element to be converted.
   * The `setPreviewImage` and `setShowModal` states are used to update the component accordingly.
/**
 * Generates a PNG image of the invoice for preview.
 * Utilizes the html2canvas library to capture the current DOM element
 * referenced by `invoiceRef`. Converts the captured canvas image to a
 * data URL and updates the `previewImage` state to display the image
 * in a modal. Sets `showModal` state to true to display the preview modal.
 */
 html2pdf().from(invoiceRef.current).save('Payscribe ' + invoiceDetails?.business.name + '/' + invoiceDetails?.id + '.pdf');
    }
  };


  const handlePreview = async () => {
    if (invoiceRef.current) {
      const canvas = await html2canvas(invoiceRef.current);
      const dataUrl = canvas.toDataURL('image/png');
      setPreviewImage(dataUrl);
      setShowModal(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const currencySymbols = {
    usd: "$",
    ngn: "₦",
  };

  const getCurrencySymbol = (currency: string) => {
    return currencySymbols[currency] || "#";
  };

  // const total_item_price = invoiceDetails?.items.map((item: { qty: string; rate: string }) => Number(item.qty) * Number(item.rate)).reduce((acc: number, value: number) => acc + value, 0);
  const total_item_price = invoiceDetails?.items.map((item: { qty: string; rate: string; type: string; amount: string }) => {
    if (item.type === "item") {
      return Number(item.qty) * Number(item.rate);
    } else {
      return Number(item.amount);
    }
  }).reduce((acc: number, value: number) => acc + value, 0);

  /**
   * Calculates the total price of the invoice after applying all discounts.
   * @returns {number} The total price of the invoice.
   */

  const calculateTotal = () => {
    let total = total_item_price;
    invoiceDetails?.discount.forEach((item) => {
      if (item.type === 'tax' || item.type === 'shipping') {
        total += item.rate;
      } else if (item.type === 'discount') {
        total -= item.rate;
      }
    });
    return total;
  };

  const formattedAmount = (amount: any) => {
    return invoiceDetails ? new Intl.NumberFormat("en-NG", {
      minimumFractionDigits: 0,
    }).format(amount)
      : null;
  }

  const today = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });


  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date.toLocaleString('en-US', { month: 'long' });
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${month} ${day}, ${year}`;
  }

  // // // console.log(invoiceDetails?.invoice_date < new Date(today) + " " + new Date(today));

  if (loading) return <Loader />;
  if (error) return <ErrorPage />;

  if (invoiceDetails) {
    return (
      <div className=''>
                <Helmet>
        <meta name="title" content={"Payscribe Invoice | " + invoiceDetails.title} />
        <meta name="description" content={invoiceDetails.description} />
  <meta name="author" content="Olamide Ashiru" />

        </Helmet>
        <div >
          <div className="grid md:grid-cols-[3fr_2fr] grid-cols-1 overflow-hidden">
            <div className=' flex justify-center items-center  bg-gradient-to-r from-blue-500 to-indigo-600 md:h-auto'>
              <div className='md:w-[70%] w-[95%] bg-white/90 md:m-1 m-5 md:p-0 p-4 text-xl'>
                <div ref={invoiceRef} className='backdrop-blur-lg w-[100%] h-full  text-black'>
                  <div className='text-center grid grid-cols-2 my-4 '>
                    <div className='flex justify-start my-5 md:mx-0 mx-2'>
                      <img src={Logo} className="h-[3.5em] mx-5 px-2" alt="Logo" />

                    </div>
                    <div className='flex justify-end items-center'>
                      {invoiceDetails.has_paid === false ?
                        <p className='m-5 text-red-800 bg-red-100 p-2 border-2 border-red-800 rounded-lg font-bold text-xl flex items-center'>Not paid</p>
                        :
                        <p className='m-5 text-green-800 bg-green-100 p-2 border-2 border-green-800 rounded-lg font-bold text-xl flex items-center'>Paid</p>
                      }
                    </div>
                  </div>
                  <div className='md:px-10 px-3 py-5'>
                    <p className="my-4">{today}</p>
                    <div>
                      <h2 className='font-semibold text-2xl'>{invoiceDetails.title}</h2>
                      <p className=' text-[#274B85]'>{invoiceDetails.description}</p>
                    </div>
                    <div className='grid my-1'>
                      <div className='font-semibold my-3 text-xl'>#{invoiceDetails.invoice_no}</div>
                      <div className="grid grid-cols-2">
                        <div>
                          <p className='font-semibold'>From {invoiceDetails.business.name}</p>
                          <p className='m-0 text-[#274B85]'>{invoiceDetails.business.address}</p>
                        </div>
                        {invoiceDetails.has_paid !== true ?
                          <div>
                            <p><span className='font-medium'>Date created:</span> <span className='text-[#274B85]'>{formatDate(invoiceDetails.invoice_date)}</span></p>
                            <p><span className='font-medium'>Payment due on:</span> <span className='text-[#274B85]'>{formatDate(invoiceDetails.due_date)}</span></p>
                          </div>
                          :
                          <div></div>
                        }

                        <div className='my-3'>
                          <p className='font-medium'>Invoice to</p>
                          <p className='font-medium'><span className='text-black  font-medium'></span>{invoiceDetails.customer.name}</p>
                          <p className='text-[#274B85]'><span className='text-black  font-medium'></span><MaskedEmail email={invoiceDetails.customer.email} /></p>
                          <p className='text-[#274B85]'><span className='text-black  font-medium'></span><MaskedPhone phone={invoiceDetails.customer.phone} /></p>
                        </div>
                        <div className="flex items-center">
                          {(today === formatDate(invoiceDetails?.due_date)) && invoiceDetails.has_paid === false && invoiceDetails.due_date !== null ?

                            <p><span className="text-xl text-red-600 font-medium">Payment is due Today</span></p>
                            :
                            new Date(today) > new Date(invoiceDetails?.due_date) && invoiceDetails.has_paid === false ?
                              <span className="text-xl text-red-600 font-medium">Payment is due by {Math.round((new Date(today) - new Date(invoiceDetails.due_date)) / (1000 * 3600 * 24))} days</span> :
                              ""
                          }
                        </div>


                      </div>
                    </div>
                  </div>
                  <div className='  text-black md:p-10 p-3'>
                    <table className='w-full  text-black border zebra-table border-[#274B85]'>
                      <thead className='text-left my-5 border-[#274B85]'>
                        <tr>
                          <th className='p-3'>Description</th>
                          <th className='p-3'>Quantity</th>
                          <th className='p-3'>Price</th>
                          <th className='p-3'>Total</th>
                        </tr>
                      </thead>
                      <tbody className='text-left my-5 border-[#274B85]'>
                        {invoiceDetails.items.map((item: {
                          unit: string;
                          amount: number;
                          type: string; description: string, qty: number, rate: number
                        }, index: number) => (
                          <tr className='my-5' key={index}>
                            <td className='p-3 font-medium'>{item.description}</td>
                            <td className='p-3'>{item.type === "item" ? item.qty : ""}</td>
                            {item.type === "item"
                              ?
                              <td className='p-3 lowercase'>{getCurrencySymbol(invoiceDetails.currency)}{formattedAmount(item.rate)} / {item.unit}</td>
                              :
                              item.type === "expense" ?
                                <td className='p-3 lowercase'>{getCurrencySymbol(invoiceDetails.currency)}{formattedAmount(item.amount)}</td>
                                :
                                <td className='p-3 lowercase'>{getCurrencySymbol(invoiceDetails.currency)}{formattedAmount(item.amount)} / {item.type === "time" && item.unit !== "day" ? item.unit : "hr"}</td>

                            }
                            <td className='p-3'>{getCurrencySymbol(invoiceDetails.currency)}{formattedAmount(item.type === "item" ? item.qty * item.rate : item.amount)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>


                  </div>

                  <div className='grid grid-cols-2 md:mx-5 mx-2'>
                    <table className='md:m-5 m-1 flex items-start justify-between'>
                      <tbody className="text-left p-3">
                        <tr className='p-2 grid grid-cols-2'>
                          <td>Subtotal:</td>
                          <td className='font-medium md:mx-5 mx-1 text-[#274B85]'>{getCurrencySymbol(invoiceDetails.currency)}{formattedAmount(total_item_price)}</td>
                        </tr>
                        {invoiceDetails.discount.map((item: {
                          title: string; type: string; rate: number
                        }, index: number) => (
                          <tr key={index} className='p-2 grid grid-cols-2'>
                            <td className=''>{item.title}:</td>
                            <td className='font-medium md:mx-5 mx-1 text-[#274B85]'>
                              {getCurrencySymbol(invoiceDetails.currency)}{formattedAmount(item.rate)}
                            </td>
                          </tr>
                        ))}
                        <tr className='p-2 grid grid-cols-2'>
                          <td className=''>Total:</td>
                          <td className='font-medium md:mx-5 mx-1 text-[#274B85]'>
                            {getCurrencySymbol(invoiceDetails.currency)}{formattedAmount(calculateTotal())}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div>
                      <table className='md:m-5 m-1 flex md:justify-center justify-start'>
                        <tbody className='text-left p-3'>
                          <tr className='p-2 grid grid-cols-2'>
                            <td className=''>To Pay:</td>
                            <td className='font-medium md:mx-5 mx-1 text-[#274B85]'>{getCurrencySymbol(invoiceDetails.currency)}{formattedAmount(calculateTotal())}</td>
                          </tr>
                          <tr className='p-2 grid grid-cols-2'>
                            <td className=''>You've Paid:</td>
                            <td className='font-medium md:mx-5 mx-1 text-[#274B85]'>{getCurrencySymbol(invoiceDetails.currency)}{invoiceDetails.amount_paid < 1 ? 0 : formattedAmount(invoiceDetails.amount_paid)}</td>
                          </tr>
                          {/* <tr className='p-2 grid grid-cols-2'>
                            <td className=''>Balance:</td>
                            <td className='font-medium md:mx-5 mx-1 text-[#274B85]'>{getCurrencySymbol(invoiceDetails.currency)}{formattedAmount(calculateTotal() - invoiceDetails.amount_paid)}</td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {invoiceDetails.note &&
                    <div className='flex justify-center'>
                      <div className="text-amber-900 bg-slate-50 max-w-md rounded-lg text-lg m-3 p-3 text-center font-medium flex justify-center items-center "><p className='flex items-center flex-col justify-center'> {invoiceDetails.note}</p>
                      </div></div>
                  }


                  <p className='py-5 text-center my-2 text-[#274B85]'> {invoiceDetails.has_paid === true ? <> <span className='font-medium text-black'>Fully paid on: </span>{formatDate(invoiceDetails.date_paid)}</> : <span className="hidden">Not paid</span>}</p>

                </div>
                <hr className='border-dashed border-[#274B85]' />
                <div className='flex justify-center my-10 flex-col md:flex-row'>
                {new Date(invoiceDetails.invoice_date) <= new Date() && invoiceDetails.has_paid === false ?

                    <div className='flex justify-center my-10 flex-col md:flex-row'>
                      <button
                        onClick={downloadInvoiceAsPDF}
                        className="md:my-0 my-1 md:mx-5 mx-2 flex justify-center items-center border-2 border-[#274B85] text-[#274B85] md:py-2 py-5 md:px-4 px-4 rounded hover:text-white hover:bg-[#274B85] font-medium transition duration-300 ease-in-out">
                        <FaDownload className='mx-1' />    Download as PDF
                      </button>
                      <button onClick={handlePreview} className="hidden md:my-0 my-1 md:mx-5 mx-2 md:flex justify-center items-center border-2 border-[#274B85] text-[#274B85] py-2 px-4 rounded hover:text-white hover:bg-[#274B85] font-medium transition duration-300 ease-in-out">Preview</button>


                      <button className="md:my-0 my-1 md:mx-5 mx-2 flex justify-center items-center border-2 border-[#274B85] text-[#274B85] md:py-2 py-5 md:px-4 px-4  rounded hover:text-white hover:bg-[#274B85] font-medium transition duration-300 ease-in-out" onClick={payNow}>Pay {" "} <span className="font-semibold mx-1">{getCurrencySymbol(invoiceDetails.currency)}{formattedAmount(calculateTotal() - invoiceDetails.amount_paid)}</span></button>
                    </div> :
                    <div className='flex justify-center items-center md:flex-row flex-col'>
                      <button onClick={handlePreview} className="hidden md:my-0 my-1 md:mx-5 mx-2 md:flex justify-center items-center border-2 border-[#274B85] text-[#274B85] py-2 px-4 rounded hover:text-white hover:bg-[#274B85] font-medium transition duration-300 ease-in-out">Preview</button>
                      <button
                        onClick={downloadInvoiceAsPDF}
                        className="md:my-0 my-1 md:mx-5 mx-2 flex justify-center items-center border-2 border-[#274B85] text-[#274B85] md:py-2 py-5 md:px-4 px-4 rounded hover:text-white hover:bg-[#274B85] font-medium transition duration-300 ease-in-out">
                        <FaDownload className='mx-1' />    Download as PDF
                      </button>
                      {/* <button className="md:my-0 my-1 md:mx-5 mx-2 flex justify-center items-center border-2 border-[#274B85] text-[#274B85] md:py-2 py-5 md:px-4 px-4  rounded hover:text-white hover:bg-[#274B85] font-medium transition duration-300 ease-in-out" onClick={payNow}>Pay now</button> */}
 {invoiceDetails.has_paid === false && <p className='text-red-500 text-center my-10 font-medium flex justify-center items-center'> <IoWarningOutline className='flex items-center mx-1' />  Oops! ou got here a little early</p>
                      } 
                     </div>
                  }
                </div>

              </div>



            </div>
            <div className='justify-center items-center fixed right-0 w-[40%] h-screen md:flex overflow-hidden font-medium text-5xl hidden'>

              <img src={Logo} className="h-[2em] px-2" alt="Logo" />

            </div>
            {showModal && (
              <div className="modal fixed top-0 left-0 w-full h-screen md:flex hidden items-center justify-center backdrop-blur-lg bg-[#274B85]/50">
                <span className="close text-white absolute top-10 left-10 text-4xl cursor-pointer" onClick={handleClose}>
                  <ImCross />
                </span> <div className="modal-content relative ">
                  <img src={previewImage} alt="Preview" className='transform md:scale-[50%] scale-[90%] md:h-[85%] h-[60em]' />
                </div>
              </div>
            )}

            <PaymentModal
              toggleModalDisplay={toggleModalDisplay}
              displayPaymentModal={displayPaymentModal}
              paymentDetails={invoiceDetails}
              payerDetails={currentCallbackView ? null : invoiceDetails.customer}
              bankDetails={bankDetails}
              setBankDetails={setBankDetails}
              balance={calculateTotal() - invoiceDetails.amount_paid}
              channel={"invoice"}
              slug={id}
      currentCallbackView={currentCallbackView}

            />

          </div>
          <p className='text-center text-[#274B85] my-10 text-2xl'>Powered by Payscribe</p>

        </div>

      </div>
    );
  }
  else {
    return <ErrorPage 
    // title="Invalid URL"
    // message="The URL you have entered is invalid."
    // description="Please confirm your the URL you are trying to access is valid"
     />
  }

};

export default InvoicePage;
