import { api } from '../config/apiConfig';

/**
 * Fetch Payment Details by slug.
 * @param {string} slug - The slug identifier.
 * @param {string} id - The id identifier.
 * @returns {Promise<Object>} - Payment details.
 * @returns {}
 */
export const fetchPaymentDetails = async (slug, payerID) => {
  try {
    const response = await api.get(`/links/${slug}`);
    // // // console.log('API Response:', response); // Debugging log
    return response.data;
  } catch (error) {
    console.error('Error fetching Payment details:', error);
    throw error;
  }
};

/**
 * Fetch Invoice Details by id.
 * @param {string} id - The id identifier.
 * @returns {Promise<Object>} - Invoice details.
 * @returns {}
 */
export const fetchInvoiceDetails = async (id) => {
  try {
    // // // console.log("Here is the" + id);
    const response = await api.get(`/invoice/${id}`);
    // // // console.log('API Response:', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching Payment details:', error);
    throw error;
  }
};



/**
 * Fetch Bank Details by slug.
 * @param {string} slug - The slug identifier.
 * @param {string} paymentMethod - The payment method.
 * @param {string} payerId - The id identifier.
 * @param {string} channel - The channel identifier.
 * @returns {Promise<Object>} - Bank details.
 * @returns {}
 */
export const fetchBankDetails = async (slug, paymentMethod, payerId, channel) => {
  try {
    const url = `links/${slug}/tab?type=${paymentMethod}&id=${payerId}&channel=${channel}`;
    const response = await api.get(url);
    // // // console.log("Bank Details Response:", response);
    return response.data;
  } catch (error) {
    // // // console.log("Error Fetching Bank Details:", error);
    throw error;
  }
};

/**
 * Fetch Bank List by slug.
 * @param {string} slug - The slug identifier.
 * @param {string} paymentMethod - The payment method.
 * @param {string} payerId - The id identifier.
 * @param {string} channel - The channel identifier.
 * @returns {Promise<Object>} - Bank list.
 * @returns {}
 */
export const fetchBanks = async (slug, paymentMethod, payerId, channel) => {
  try{
    const url = `links/${slug}/tab?type=${paymentMethod}&id=${payerId}&channel=${channel}`;
    const response = await api.get(url);
    // // // console.log("Bank List", response.data)
    return response.data;
  } catch (error) {
    throw error;
  }
}

// export const sendWalletAccount = async ()


/**
 * Load Payment Link by slug with source parameter.
 * @param {string} slug - The slug identifier.
 * @returns {Promise<Object>} - Payment link data.
 */
export const loadPaymentLink = async (slug) => {
  try {
    const response = await api.get(`/links/${slug}`, {
      params: { source: 'api' },
    });
    // // // console.log('API Load Payment Link Response:', response); // Debugging log
    return response.data;
  } catch (error) {
    // console.error('Error loading payment link:', error);
    throw error;
  }
};

/**
 * Sign in to Payscribe account.
 * @param {Object} credentials - User credentials.
 * @param {string} slug - The slug identifier.
 * @returns {Promise<Object>} - Sign-in response.
 */
export const signInToPayscribeAccount = async (credentials, slug) => {
  try {
    const response = await api.post(`/validatePayscribe/${slug}`, credentials);
    // // // console.log('API Sign-In Response:', response); // Debugging log
    return response.data;
  } catch (error) {
    // console.error('Error signing in:', error);
    throw error;
  }
};

